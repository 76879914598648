import React, { Component } from "react";
import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBBtn,
	MDBModal,
	toast,
	ToastContainer,
	MDBInput,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBIcon
} from "mdbreact";
import Navigation from "../../../components/VenueMgmt/Navigation/Navigation";
import Support from "../../../components/VenueMgmt/Support/Support";
import QuickLinks from "../../../components/VenueMgmt/QuickLinks/QuickLinks";
import ResidentSummary from "../../../components/VenueMgmt/ResidentSummary/ResidentSummary";
import RecentResidents from "../../../components/VenueMgmt/RecentResidents/RecentResidents";
import ActionSummary from "../../../components/VenueMgmt/ActionSummary/ActionSummary";
import UpcomingExpirations from "../../../components/VenueMgmt/UpcomingExpirations/UpcomingExpirations";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import axios from "../../../axios";
import _ from "lodash";
import { Search, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
// import Joyride from "react-joyride";
import ResidentsTab from "../../../assets/images/residents-tab.png";
import GuestsTab from "../../../assets/images/guests-tab.png";
import SupportTab from "../../../assets/images/support-tab.png";
import SettingsTab from "../../../assets/images/settings-tab.png";
import MyAccount from "../../../assets/images/myAccount.png";
import SwitchAccounts from "../../../assets/images/switchAccounts.png";
import LogoFooter from "../../../components/Logo/logoFooter";
import { ErrorBoundary } from "react-error-boundary";
const initialState = { isLoading: false, results: [], value: "" };
let getResults;
let source;
let source1 = [];
const root = document.documentElement;
class Dashboard extends Component {
	state = {
		selectedName:
			sessionStorage.getItem("venueName") !== undefined
				? sessionStorage.getItem("venueName")
				: null,
		selectedID:
			sessionStorage.getItem("venueID") !== undefined
				? sessionStorage.getItem("venueID")
				: null,
		initialState,
		venueData: [],
		activeData: undefined,
		refreshed: false,
		loading: false,
		venueType: "",
		buildings: [],
		modalModifyResident: false,
		run: false,
		tourNotShownAgain: undefined,
		darkmode: localStorage.getItem("darkMode"),
		showError: false
	};
	componentDidMount() {
		this.getPersonalData();
		this.getVenueDetails();
		this.getSupportDetails();
		this.getBuildings();
  // this.getDisplayOptions();
	}
	handleSwitchChange = (nr) => () => {
		this.setState({
			darkmode: !JSON.parse(this.state.darkmode),
		});
		localStorage.setItem("darkMode", !JSON.parse(this.state.darkmode));
		let element = document.getElementsByTagName("body")[0];
		element.setAttribute(
			"data-theme",
			"darkMode-" + !JSON.parse(this.state.darkmode)
		);
  this.getDisplayOptions();
	};
	searchData = () => {
		return this.state.searchData;
	};
	updateName = (value, index) => {
		sessionStorage.setItem("venueName", value);
		sessionStorage.setItem("venueID", index);
		this.setState({ selectedName: value });
		this.setState({ selectedID: index });
		this.getVenueDetails(index);
		this.getSupportDetails(index);
		this.getBuildings(index);
	};
	updateVenueList = (venueListIn) => {
		let venueName1 = [];
		let venueListNameTemp = [];
		for (
			let count = 0;
			count <= Object.keys(venueListIn).length - 1;
			count++
		) {
			let venueList_a = Object.keys(venueListIn);
			venueName1[venueList_a[count]] =
				venueListIn[venueList_a[count]]["VenueName"];
			venueListNameTemp = [...venueListNameTemp, venueName1];
		}
		this.setState({
			venueList: venueListIn,
			venueListName: venueListNameTemp,
		});
	};

	getPersonalData = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: sessionStorage.getItem("userEmail")
		}
		axios
			.post("/subscriberconsole/listKeys", body, {
				headers: {},
			})
			.then((response) => {
				sessionStorage.setItem("firstName", response.data.GivenName);
				sessionStorage.setItem("lastName", response.data.FamilyName);
				sessionStorage.setItem("phoneNumber", response.data["PhoneNumber.E164"]);
				sessionStorage.setItem("email", response.data.EmailAddress);
			})
			.catch((error) => {
				this.setState({ error: true });
			});
	}
//  getDisplayOptions = () => {
//   let body = {
//     AccessToken: sessionStorage.getItem("AccessToken"),
//   };
//   axios
//     .post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
//       headers: {},
//     })
//     .then((response) => {
      
//       // sessionStorage.setItem(
//       //   "cartEnabledGlobally",
//       //   response.data.ShoppingCart.enableShoppingCart
//       // );
      
//         // globalNetworkConfiguration: response.data["NetworkConfiguration"],
//         // globalMessagingConfiguration: response.data["Notifications"],
//         // globalRealPageConfiguration: response.data["PMS"],
//         // globalShoppingCartConfiguration: response.data["ShoppingCart"],
//         // allowOverlapping:
//         //   response.data["NetworkConfiguration"][
//         //     "allowOverlappingSubscriptionWiredUnit"
//         //   ],
//         // selectDefaultServicePlanResident:
//         //   response.data["ServicePlanConfiguration"][
//         //     "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
//         //   ],
//         // selectDefaultServicePlanWireless:
//         //   response.data["ServicePlanConfiguration"][
//         //     "DefaultServicePlanID_LocalWirelessAccess"
//         //   ],
//         // globalKeyPoolList:
//         //   response.data["NetworkConfiguration"]["GlobalSSID"],
//         // operatorID:
//         //   response.data["OperatorID"]
//       });
//     .catch((error) => {});
// };
getDisplayOptions = () => {
 let body = {
   AccessToken: sessionStorage.getItem("AccessToken"),
 };
 axios
   .post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
     headers: {},
   })
   .then((response) => {
     const root = document.documentElement;
     var title = document.createElement("title");
     var description = document.createElement("meta");
     // console.log("theme changed")
     let theme;
     if (
       localStorage.getItem("darkMode") === "false" ||
       localStorage.getItem("darkMode") === null
     ) {
       theme = "ThemeLight";
     } else {
       theme = "ThemeDark";
     }
     // console.log(theme)
     root?.style.setProperty(
       "--background-color",
       response.data.DisplayConfiguration[theme]["BackgroundColorHex"]
     );
     root?.style.setProperty(
       "--modal-background-color",
       response.data.DisplayConfiguration[theme]["ModalColorHex"]
     );
     root?.style.setProperty(
       "--primary-text-color",
       response.data.DisplayConfiguration[theme]["PrimaryTextColorHex"]
     );
     root?.style.setProperty(
       "--primary-color",
       response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
     );
     root?.style.setProperty(
       "--secondary-color",
       response.data.DisplayConfiguration[theme]["SecondaryColorHex"]
     );
     root?.style.setProperty(
      "--border-color",
      '#BFBFBF'
    );
     root?.style.setProperty(
       "--primary-color-rgb",
       response.data.DisplayConfiguration[theme]["PrimaryColorHex"] + "4D"
     );
     root?.style.setProperty(
       "--button-color",
       response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
     );
     root?.style.setProperty(
       "--button-text-color",
       '#FFF'
     );
     root?.style.setProperty(
       "--summary-card-color",
       response.data.DisplayConfiguration[theme]["SecondaryColorHex"]
     );
     root?.style.setProperty(
       "--navbar-text-color",
       response.data.DisplayConfiguration[theme]["PrimaryTextColorHex"]
     );
     root?.style.setProperty(
       "--navbar-color",
       response.data.DisplayConfiguration[theme]["NavbarColorHex"]
     );
     root?.style.setProperty(
       "--card-color",
       response.data.DisplayConfiguration[theme]["NavbarColorHex"]
     );
     root?.style.setProperty("--button-text-color", "#FFF");
     root?.style.setProperty(
       "--input-color",
       response.data.DisplayConfiguration[theme]["InputBoxColorHex"]
     );
     root?.style.setProperty(
       "--input-focus-color",
       response.data.DisplayConfiguration[theme]["BannerColor2Hex"]
     );
     sessionStorage.setItem(
       "cartEnabledGlobally",
       response.data.ShoppingCart.enableShoppingCart
     );
     this.setState({
       s3Directory:
         response.data.DisplayConfiguration["Behavior"]["S3Directory"],
       logo: response.data.DisplayConfiguration[theme][
         "LogoFileNameDesktopMobileAfterLogin"
       ],
       displayOptionID: response.data.DisplayOptionID,
       operatorName: response.data.NetworkOperatorName,
       cancelInputType:
         response.data["DisplayConfiguration"]["Behavior"][
           "CancellationMethod"
         ],
       showUpdate:
         response.data["DisplayConfiguration"]["ShoppingCart"][
           "AllowSelfPaymentMethodUpdate"
         ],
       showSupport:
         response.data.DisplayConfiguration["Support"]["ShowSupportLinks"],
       externalSupport:
         response.data.DisplayConfiguration["Support"][
           "ExternallyManagedSupport"
         ],
       externalSupportLink:
         response.data.DisplayConfiguration["Support"][
           "ExternalSupportSystemURL"
         ],
       showDevices:
         response.data.DisplayConfiguration[
           "ShowEquipmentStatusToVenueAdministrator"
         ],
       // globalNetworkConfiguration: response.data["NetworkConfiguration"],
       // globalMessagingConfiguration: response.data["Notifications"],
       // globalRealPageConfiguration: response.data["PMS"],
       // globalShoppingCartConfiguration: response.data["ShoppingCart"],
       // allowOverlapping:
       //   response.data["NetworkConfiguration"][
       //     "allowOverlappingSubscriptionWiredUnit"
       //   ],
       // selectDefaultServicePlanResident:
       //   response.data["ServicePlanConfiguration"][
       //     "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
       //   ],
       // selectDefaultServicePlanWireless:
       //   response.data["ServicePlanConfiguration"][
       //     "DefaultServicePlanID_LocalWirelessAccess"
       //   ],
       // globalKeyPoolList:
       //   response.data["NetworkConfiguration"]["GlobalSSID"],
       operatorID: response.data["OperatorID"],
     });
     sessionStorage.setItem(
       "cancelInputType",
       response.data.DisplayConfiguration["Behavior"]["CancellationMethod"]
     );
     sessionStorage.setItem(
       "showUpdate",
       response.data.DisplayConfiguration["ShoppingCart"][
         "AllowSelfPaymentMethodUpdate"
       ]
     );
     sessionStorage.setItem("loggedInNO", true);
   })
   .catch((error) => {});
};
	getVenueDetails = (index) => {
		this.setState({ loading: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: index,
			UserTimeZone: new Date().getTimezoneOffset(),
		};
		let key = [];
		let iteration = 0;
		let lastName = [];
		let firstName = [];
		let unitID = [];
		let unitID_internal = [];
		let keyID = [];
		let email = [];
		let startDate = [];
		let contactpref_email = [];
		let contactpref_sms = [];
		let phoneNumber = [];
		let locale = [];
		let endDate = [];
		let floor = [];
		let building = [];
		let ssid = [];
		let servicePlanID = [];
		let servicePlan = [];
		let vlan = [];
		let residentStatus = [];
		let realpageSyncDate = [];
		let subscriberManagedviaSSO = [];
		let StripeClientSecret = [];
		let paymentFailed = [];
		let RealPageUnitID = [];
		let subscriptionDiscount = [];
		let venueDiscount = [];
		let externalSubscriberID = [];
		let selectedServicePlanPrice = [];
		let setupIntentID = [];
		source1 = [];
		this.setState({
			activeData: undefined,
			activeData1Day: undefined,
			activeData7Day: undefined,
			expiringData: undefined,
			expiringData14Days: undefined,
			searchData: undefined,
			guestDataExpiring: undefined,
			value: "",
			UnitsBuildingFloorListSusbcribers: undefined
		});
		if (index !== undefined) {
			axios
				.post("/venuemgmtconsole/venues/listVenueDetails", body, {
					headers: {}
				})
				.then((response) => {
     const root = document.documentElement;
      console.log("theme changed")
      let theme;
      if (
        localStorage.getItem("darkMode") === "false" ||
        localStorage.getItem("darkMode") === null
      ) {
        theme = "ThemeLight";
      } else {
        theme = "ThemeDark";
      }
      console.log(theme)
      root?.style.setProperty(
        "--background-color",
        response.data[index]["DisplayConfiguration"][theme]["BackgroundColorHex"]
      );
      root?.style.setProperty(
        "--modal-background-color",
        response.data[index]["DisplayConfiguration"][theme]["ModalColorHex"]
      );
      root?.style.setProperty(
        "--primary-text-color",
        response.data[index]["DisplayConfiguration"][theme]["PrimaryTextColorHex"]
      );
      root?.style.setProperty(
        "--primary-color",
        response.data[index]["DisplayConfiguration"][theme]["PrimaryColorHex"]
      );
      root?.style.setProperty(
        "--secondary-color",
        response.data[index]["DisplayConfiguration"][theme]["SecondaryColorHex"]
      );
      root?.style.setProperty(
        "--secondary-text-color",
        response.data[index]["DisplayConfiguration"][theme]["SecondaryTextColorHex"]
      );
      root?.style.setProperty(
        "--border-color",
        response.data[index]["DisplayConfiguration"][theme]["BorderColorHex"]
      );
      root?.style.setProperty(
        "--primary-color-rgb",
        response.data[index]["DisplayConfiguration"][theme]["PrimaryColorHex"] + "4D"
      );
      root?.style.setProperty(
        "--link-color",
        response.data[index]["DisplayConfiguration"][theme]["LinkColorHex"]
      );
      root?.style.setProperty(
        "--button-color",
        response.data[index]["DisplayConfiguration"][theme]["PrimaryColorHex"]
      );
      root?.style.setProperty(
        "--button-text-color",
        "#FFF"
      );
      root?.style.setProperty(
        "--summary-card-color",
        "#000"
      );
      root?.style.setProperty(
        "--navbar-text-color",
        response.data[index]["DisplayConfiguration"][theme]["PrimaryTextColorHex"]
      );
      root?.style.setProperty(
        "--navbar-color",
        response.data[index]["DisplayConfiguration"][theme]["NavbarColorHex"]
      );
      root?.style.setProperty(
        "--card-color",
        response.data[index]["DisplayConfiguration"][theme]["NavbarColorHex"]
      );
      root?.style.setProperty(
        "--input-color",
        response.data[index]["DisplayConfiguration"][theme]["InputBoxColorHex"]
      );
      root?.style.setProperty(
        "--input-focus-color",
        response.data[index]["DisplayConfiguration"][theme]["BannerColor2Hex"]
      );
     //  console.log(response.data.DisplayConfiguration[theme][
     //   "LogoFileNameDesktopMobileAfterLogin"
     // ])
      this.setState({
       s3Directory:
       response.data[index]["DisplayConfiguration"]["Behavior"]["S3Directory"],
     logo: response.data[index]["DisplayConfiguration"][theme][
       "LogoFileNameDesktopMobileAfterLogin"
     ],
        displayOptionID: response.data.DisplayOptionID,
        // operatorName: response.data.NetworkOperatorName,
        cancelInputType:
        response.data[index]["DisplayConfiguration"]["Behavior"][
          "CancellationMethod"
        ],
        showUpdate:
        response.data[index]["DisplayConfiguration"]["ShoppingCart"][
          "AllowSelfPaymentMethodUpdate"
        ],
        showSupport:
          response.data[index]["DisplayConfiguration"]["Support"]["ShowSupportLinks"],
        externalSupport:
          response.data[index]["DisplayConfiguration"]["Support"][
            "ExternallyManagedSupport"
          ],
        externalSupportLink:
          response.data[index]["DisplayConfiguration"]["Support"][
            "ExternalSupportSystemURL"
          ],
        showDevices:
          response.data[index]["DisplayConfiguration"][
            "ShowEquipmentStatusToVenueAdministrator"
          ]
         })
         sessionStorage.setItem(
          "cancelInputType",
          response.data[index]["DisplayConfiguration"]["Behavior"]["CancellationMethod"]
        );
        sessionStorage.setItem(
          "showUpdate",
          response.data[index]["DisplayConfiguration"]["ShoppingCart"][
            "AllowSelfPaymentMethodUpdate"
          ]
        );
        sessionStorage.setItem("loggedInNO", true);
					this.setState({
						leasingAgents: [],
						venueAdmins: [],
					});
					let sortedMostRecentSubscriptionData = _(
						response.data[index][
						"RecentlyStartedSubscriptionsList_7day"
						]
					)
						.map(function (v, k) {
							// insert the key into the object
							return _.merge({}, v, { key: k });
						})
						.sortBy("StartDateTimeUTC.ISO8601")
						.reverse() // sort by name
						.value();
					let sortedSubscriptionData = _(
						response.data[index]["Subscriptions"]
					)
						.map(function (v, k) {
							// insert the key into the object
							return _.merge({}, v, { key: k });
						})
						.sortBy("StartDateTimeUTC.ISO8601")
						.reverse() // sort by name
						.value();
					let sortedActive1Day = _(
						response.data[index]["UpcomingSubscriptionsList_1day"]
					)
						.map(function (v, k) {
							// insert the key into the object
							return _.merge({}, v, { key: k });
						})
						.sortBy("StartDateTimeUTC.ISO8601")
						.reverse() // sort by name
						.value();
					let sortedActive7Day = _(
						response.data[index]["UpcomingSubscriptionsList_7day"]
					)
						.map(function (v, k) {
							// insert the key into the object
							return _.merge({}, v, { key: k });
						})
						.sortBy("StartDateTimeUTC.ISO8601")
						.reverse() // sort by name
						.value();
					this.setState({
						address1: response.data[index]["AddressLine1"],
						address2: response.data[index]["AddressLine2"],
						city: response.data[index]["City"],
						state: response.data[index]["State"],
						postalCode: response.data[index]["PostalCode"],
						country: response.data[index]["Country.ISO3166A3"],
						venueData: response.data[index],
						activeData: sortedMostRecentSubscriptionData,
						activeData1Day: sortedActive1Day,
						activeData7Day: sortedActive7Day,
						allActiveData: sortedSubscriptionData,
						expiringData:
							response.data[index][
							"UpcomingSubscriptionEndList_7day_unconfirmed"
							],
						expiringData14Days:
							response.data[index][
							"UpcomingSubscriptionEndList_14day"
							],
						searchData:
							response.data[index]["UnitsAndSubscriptions"],
						keypoolID: Object.keys(
							response.data[index]["Keypools"]
						),
						guestData: response.data[index]["GuestSubscriptions"],
						guestDataExpiring:
							response.data[index][
							"UpcomingGuestSubscriptionEndList_14day"
							],
						venueAdmins:
							response.data[index]["VenueAdministrators"],
						leasingAgents: response.data[index]["LeasingAgents"],
						UnitsBuildingFloorListSusbcribers:
							response.data[index][
							"Units-Building-Floor-List-Subscribers"
							],
						venueServicePlans:
							response.data[index]["AvailableServicePlans"],
						venueType: response.data[index]["VenueType"]
					});
					key = Object.keys(response.data[index]["Subscriptions"]);
					key.forEach((val) => {
						if (
							response.data[index]["Subscriptions"][
							key[iteration]
							]["StartDateTimeUTC.ISO8601"] !== ""
						) {
							lastName =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["SubscriberFamilyName"];
							firstName =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["SubscriberGivenName"];
							unitID =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["UnitID"];
							unitID_internal =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["UnitID_internal"];
							startDate =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["StartDateTimeUTC.ISO8601"];
							contactpref_email =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["contactpref_email"];
							contactpref_sms =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["contactpref_sms"];
							phoneNumber =
								response.data[index]["Subscriptions"][
									key[iteration]
								]["SubscriberPhoneNumber.E164"].toString();
							locale =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["SubscriberLocale"];
							endDate =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["EndDateTimeUTC.ISO8601"];
							floor =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["UnitFloor"];
							building =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["UnitBuilding"];
							email =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["SubscriberEmail"];
							ssid =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["ssid"];
							keyID = key[iteration];
							servicePlanID =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["ServicePlan"]["ServicePlanID"];
							servicePlan =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["ServicePlan"]["ServicePlanName"];
							servicePlan =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["ServicePlan"]["ServicePlanName"];
							vlan =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["vlan"];
							residentStatus =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["Status"];
							realpageSyncDate =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["RealPageSyncDateTimeUTC.ISO8601"];
							subscriberManagedviaSSO =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["SubscriberManagedviaSSO"];
							StripeClientSecret =
								response.data[index]["Subscriptions"][
								key[iteration]
								]["StripeClientSecret"];
							paymentFailed = response.data[index]["Subscriptions"][key[iteration]]["paymentFailed"];
							RealPageUnitID = response.data[index]["Subscriptions"][key[iteration]]["RealPageUnitID"];
							subscriptionDiscount = response.data[index]["Subscriptions"][key[iteration]]["VenueUnitShoppingCartPriceAdjustment"];
							venueDiscount = response.data[index]["Subscriptions"][key[iteration]]["VenueShoppingCartPriceAdjustment"];
							externalSubscriberID = response.data[index]["Subscriptions"][key[iteration]]["externalSubscriberID"];
							selectedServicePlanPrice = response.data[index]["Subscriptions"][key[iteration]]["ListBillingCyclePrice"];
							setupIntentID = response.data[index]["Subscriptions"][key[iteration]]["SetupIntentId"]
							getResults = () =>
								_.times(1, () => ({
									firstName: firstName,
									lastName: lastName,
									unitID: unitID,
									unitID_internal: unitID_internal,
									startDate: startDate,
									contactpref_email: contactpref_email,
									contactpref_sms: contactpref_sms,
									phoneNumber: phoneNumber,
									locale: locale,
									endDate: endDate,
									floor: floor,
									building: building,
									title: firstName + " " + lastName,
									description: `Unit: ${unitID} \r\nBuilding: ${building}, Floor: ${floor} \r\n${email}`,
									keyid: keyID,
									email: email,
									ssid: ssid,
									servicePlanID: servicePlanID,
									servicePlan: servicePlan,
									vlan: vlan,
									status: residentStatus,
									realpageSyncDate: realpageSyncDate,
									subscriberManagedviaSSO:
										subscriberManagedviaSSO,
									StripeClientSecret: StripeClientSecret,
									paymentFailed: paymentFailed,
									RealPageUnitID: RealPageUnitID,
									subscriptionDiscount: subscriptionDiscount,
									venueDiscount: venueDiscount,
									externalSubscriberID: externalSubscriberID,
									selectedServicePlanPrice: selectedServicePlanPrice,
									setupIntentID: setupIntentID
								}));
							source = _.range(0, 3).reduce((memo1) => {
								const name = val;
								// eslint-disable-next-line no-param-reassign
								memo1 = {
									name,
									results: getResults(),
								};
								return memo1;
							}, {});
							source1.push(source);
							iteration = iteration + 1;
						}
					});
					this.setState({ loading: false });
				})
				.catch((error) => {
					this.getVenueDetails();
					this.setState({ showError: true });
				});
		}
	};
	getSupportDetails = (index) => {
		//#region Support Tickets
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: index,
		};
		this.setState({
			ticketCount: undefined,
			tickets: undefined,
		});
		if (index !== undefined) {
			axios
				.post("/venuemgmtconsole/support/getTickets", body, {
					headers: {},
				})
				.then((response) => {
					this.setState({
						ticketCount: response.data.TicketCount,
						tickets: response.data.Tickets,
					});
				})
				.catch((error) => {
					this.setState({ error: true });
				});
			//#endregion
			//#region Get Core Equipment Status
			let coreEquipmentBody = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				VenueID: index,
				UserTimeZone: new Date().getTimezoneOffset(),
			};
			this.setState({
				coreEquipmentState: {
					UP: 0,
					TOTAL: 0,
					Devices: [],
				},
			});
			//CHANGE AFTER API POST
			axios
				.post(
					"/venuemgmtconsole/venues/listVenueDetails",
					coreEquipmentBody,
					{
						headers: {},
					}
				)
				.then((response) => {
					this.setState({
						coreEquipmentState: {
							UP: response["data"][index]["CoreEquipmentUPCount"],
							TOTAL: response["data"][index][
								"CoreEquipmentCount"
							],
							Devices: [
								response["data"][index]["CoreEquipmentStatus"],
							],
						},
					});
				})
				.catch((error) => {
					this.setState({ error: true });
				});
			//#endregion
			//#region Get CPE Status
			let cpeEquipmentBody = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				VenueID: index,
			};
			this.setState({
				cpeEquipmentState: {
					UP: 0,
					DOWN: 0,
					TOTAL: 0,
					Devices: [],
				},
			});
			//CHANGE AFTER API POST
			axios
				.post(
					"/venuemgmtconsole/venues/listVenueCPEStatus",
					cpeEquipmentBody,
					{
						headers: {},
					}
				)
				.then((response) => {
					this.setState({
						cpeEquipmentState: {
							UP: response["data"]["Summary"]["UP"],
							DOWN: response["data"]["Summary"]["DOWN"],
							TOTAL:
								response["data"]["Summary"]["UP"] +
								response["data"]["Summary"]["DOWN"],
							Devices: response["data"]["Devices"],
						},
					});
				})
				.catch((error) => {
					this.setState({ error: true });
				});
		}
		//#endregion
	};
	getBuildings = (index) => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: index,
		};
		if (index !== undefined) {
			axios
				.post("/venuemgmtconsole/venues/listVenueBuildings", body, {
					headers: {},
				})
				.then((response) => {
					this.setState({
						buildings: response.data.UnitBuildings,
					});
				})
				.catch((error) => {
					this.setState({ error: true });
				});
		}
	};
	handleResultSelect = (e, { result }) => {
		this.setState({ SubscriberGivenName: result.firstName });
		this.setState({ SubscriberFamilyName: result.lastName });
		this.setState({ UnitID: result.unitID });
		this.setState({ unitID_internal: result.unitID_internal });
		this.setState({ StartDateTime: result.startDate });
		this.setState({ contactpref_email: result.contactpref_email });
		this.setState({ contactpref_sms: result.contactpref_sms });
		this.setState({ SubscriberPhoneNumber: result.phoneNumber });
		this.setState({ SubscriberLocale: result.locale });
		this.setState({ EndDateTime: result.endDate });
		this.setState({ UnitFloor: result.floor });
		this.setState({ UnitBuilding: result.building });
		this.setState({ SubscriberEmail: result.email });
		this.setState({ redirect: true });
		this.setState({ value: result.title });
		this.setState({ ssid: result.ssid });
		this.setState({ servicePlanID: result.servicePlanID });
		this.setState({ servicePlan: result.servicePlan });
		this.setState({ vlan: result.vlan });
		this.setState({ status: result.status });
		this.setState({
			subscriberManagedviaSSO: result.subscriberManagedviaSSO,
			StripeClientSecret: result.StripeClientSecret
		});
		this.setState({ realpageSyncDate: result.realpageSyncDate });
		this.setState({ RealPageUnitID: result.RealPageUnitID });
		this.setState({
			modalModifyResident: !this.state.modalModifyResident,
		});
		this.setState({ paymentFailed: result.paymentFailed });
		this.setState({ subscriptionDiscount: result.subscriptionDiscount });
		this.setState({ venueDiscount: result.venueDiscount });
		this.setState({ selectedServicePlanPrice: result.selectedServicePlanPrice });
		this.setState({ setupIntentID: result.setupIntentID});
	};
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	toggleModifyResident = () => {
		this.setState({
			modalModifyResident: !this.state.modalModifyResident,
		});
	};
	toggleModifyService = () => {
		this.setState({
			modalModifyServiceInfo: !this.state.modalModifyServiceInfo,
		});
	};
	refresh = () => {
		this.setState({ refreshed: true });
	};
	handleSearchChange = (e, { value }) => {
		this.setState({ isLoading: true, value });
		let re = "";
		setTimeout(() => {
			this.setState({ results: "" });
			if (this.state.value.length < 1) return this.setState(initialState);
			re = new RegExp(_.escapeRegExp(this.state.value), "i");
			const isMatch = (result) =>
				re.test(result.title + result.description + result.email);
			this.setState({
				filteredResults: _.reduce(
					source1,
					(memo2, data, name) => {
						let results = _.filter(data.results, isMatch);
						if (results.length || results[0] !== undefined)
							memo2[name] = { name, results }; // eslint-disable-line no-param-reassign
						return memo2;
					},
					{}
				),
			});
			this.setState({
				isLoading: false,
				results: this.state.filteredResults,
			});
		}, 300);
	};
	submitModify = (
		email,
		firstName,
		lastName,
		phoneNumber,
		language,
		contactpref_email,
		contactpref_sms,
		recentResidents,
		dateStart,
		oldDateStart,
		dateEnd,
		oldDateEnd,
		venueID,
		unitID,
		building,
		floor,
		servicePlanID,
		isUpdated,
		isUpdatedServicePlan,
		isUpdatedStartDate,
		isUpdatedEndDate,
		isUpdatedSubscriptionDiscount,
		unitID_internal,
		selectedServicePlanName,
		subscriberToken,
		updatedUnit,
		subscriptionDiscount,
		setupIntentID
	) => {
		this.setState({ loading: true });
		let bodyDateStart = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			VenueID: venueID,
			UnitID_internal: unitID_internal,
			"StartDateTimeUTC.ISO8601": new Date(oldDateStart)
				.toISOString()
				.replace(/:00.\d+Z$/g, "Z"),
			"NewStartDateTimeUTC.ISO8601": new Date(dateStart)
				.toISOString()
				.replace(/:00.\d+Z$/g, "Z"),
			UserTimeZone: new Date().getTimezoneOffset(),
			SubscriberToken: subscriberToken
		};
		let bodyDateEnd = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			VenueID: venueID,
			UnitID_internal: unitID_internal,
			"StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
			"NewEndDateTimeUTC.ISO8601":
				dateEnd !== undefined
					? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
					: undefined,
			UserTimeZone: new Date().getTimezoneOffset(),
			SubscriberToken: subscriberToken
		};
		let bodyServicePlan = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			VenueID: venueID,
			UnitID_internal: unitID_internal,
			"CurrentStartDateTimeUTC.ISO8601": new Date(
				oldDateStart
			).toISOString(),
			NewServicePlanID: servicePlanID,
			UserTimeZone: new Date().getTimezoneOffset(),
			SubscriberToken: subscriberToken
		};
		let bodyUpdatePII = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			GivenName: firstName,
			FamilyName: lastName,
			"PhoneNumber.E164": phoneNumber,
			locale: language,
			contactpref_email: true,
			contactpref_sms: contactpref_sms,
			VenueID: venueID,
			SubscriberToken: subscriberToken
		};
		let bodyUpdateUnit = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
			SetupIntentId: setupIntentID,
			NewUnitID_internal: unitID_internal,
			VenueID: venueID
		};
		let bodyUpdateSubscriberPrice = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
			SetupIntentId: setupIntentID,
			NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
			"NewEndDateTimeUTC.ISO8601":
				dateEnd !== undefined
					? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
					: "",
		}
		Promise.all([
			new Date(dateStart) > new Date() && isUpdatedStartDate === true
				? axios.post(
					"/venuemgmtconsole/subscribers/resetSubscriptionStartDate",
					bodyDateStart,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: Dashboard.js:730 ~ Dashboard ~ bodyDateStart", bodyDateStart)
			,
			dateEnd !== undefined && isUpdatedEndDate === true
				? axios.post(
					"/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
					bodyDateEnd,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: Dashboard.js:739 ~ Dashboard ~ bodyDateEnd", bodyDateEnd),
			isUpdated === true
				? axios.post(
					"/venuemgmtconsole/subscribers/updateSubscriberPII",
					bodyUpdatePII,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: Dashboard.js:747 ~ Dashboard ~ bodyUpdatePII", bodyUpdatePII),
			updatedUnit === true
				? axios.post(
					"/venuemgmtconsole/subscribers/modifyVenueUnit",
					bodyUpdateUnit,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit", bodyUpdateUnit),
			isUpdatedServicePlan === true
				? axios.post(
					"/venuemgmtconsole/subscribers/modifyServicePlan",
					bodyServicePlan,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: Dashboard.js:755 ~ Dashboard ~ bodyServicePlan", bodyServicePlan),
			isUpdatedSubscriptionDiscount === true
				? axios.post(
					"/venuemgmtconsole/subscribers/modifySubscriptionPrice",
					bodyUpdateSubscriberPrice,
					{ headers: {} }
				)
				:
				console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice", bodyUpdateSubscriberPrice),
		])
			.then(async ([res1, res2, res3, res4, res5, res6]) => {
				toast.success("Updated Resident!", {
					closeButton: false,
				});
				this.toggleModifyResident();
				this.setState({ activeData: undefined });
				this.getVenueDetails(venueID);
				if (isUpdatedServicePlan === true) {
					this.setState({
						loading: false,
						nextInvoiceAdjustedAmount:
							res4.data["NextInvoiceAdjustedAmount.USD"] === undefined ? "" : res4.data["NextInvoiceAdjustedAmount.USD"] / 100,
						nextInvoiceDate:
							res4.data["NextInvoiceDateTimeUTC.ISO8601"],
						billingCycleAdjustment:
							res4.data["BillingCycleAdjustments.USD"] === undefined ? "" : res4.data["BillingCycleAdjustments.USD"] / 100,
						subscriberName: firstName + " " + lastName,
						subscriberEmail: email,
						newServicePlanName: selectedServicePlanName,
					});
					this.toggleModifyService();
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				toast.error("There was an error!", {
					closeButton: false,
				});
				this.toggleModifyResident();
				this.setState({ recentResidents: recentResidents });
				this.setState({ loading: false });
			});
	};
	submitDelete = (
		email,
		venueID,
		unitID,
		building,
		floor,
		recentResidents,
		oldDateStart,
		unitID_internal,
		cancellationReason,
		refundAmount
	) => {
		this.setState({ loadingDelete: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			VenueID: venueID,
			UnitID_internal: unitID_internal,
			endImmediately: true,
			"StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
			UserTimeZone: new Date().getTimezoneOffset(),
			"CancellationReasons": cancellationReason,
			"requestedRefundAmount": refundAmount
		};
		axios
			.post(
				"/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
				body,
				{
					headers: {},
				}
			)
			.then(
				(response) => {
					toast.success("Deleted Resident!", {
						closeButton: false,
					});
					this.toggleModifyResident();
					this.setState({ activeData: undefined });
					this.getVenueDetails(venueID);
					this.setState({ loadingDelete: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleModifyResident();
					this.setState({ recentResidents: recentResidents });
					this.setState({ loadingDelete: false });
				}
			);
	};
	closeTour2 = () => {
		this.setState({
			isTourOpen: !this.state.isTourOpen,
		});
	};
	closeTour = () => {
		this.setState({
			isTourOpen: !this.state.isTourOpen,
			steps: [
				{
					content: (
						<div className="first-step-content">
							<h4>Welcome to Dish Fiber!</h4>
							<p>
								Your simple Wi-Fi dashboard with real-time
								analytics
							</p>
							<MDBInput
								label="Do not show again"
								type="checkbox"
								id="tourNotShownAgain"
								name="tourNotShownAgain"
								onChange={this.toggleCheckboxValueTour}
							/>
						</div>
					),
					placement: "center",
					target: "body",
					disableOverlayClicks: true,
				},
				{
					target: ".second-step",
					content: (
						<div className="second-step-content">
							<h4>Activity Summary</h4>
							<p>
								A pulse of new residents joining the network and
								those with contracts about to expire
							</p>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".third-step",
					content: (
						<div className="third-step-content">
							<h4>Service Status</h4>
							<p>
								Track resdients with pending AUP Acceptance
								status.
							</p>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".support-step",
					content: (
						<div className="third-step-content">
							<h4>Support Status</h4>
							<p>
								View the number of open tickets and monitor
								equipment status with green-yellow-red alert
								bubbles
							</p>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".fourth-step",
					content: (
						<div className="third-step-content">
							<h4>Quick Search and Links</h4>
							<p>
								Do a quick search or jump to popular features by
								clicking the icon
							</p>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".fifth-step",
					content: (
						<div className="third-step-content">
							<h4>Recent Service Activations</h4>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".sixth-step",
					content: (
						<div className="third-step-content">
							<h4>Upcoming Expirations</h4>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".seventh-step",
					content: (
						<div>
							<h4>Residents</h4>
							<br />
							<img
								src={ResidentsTab}
								alt="Residents Tab"
								style={{ width: "500px" }}
							/>
						</div>
					),
					styles: {
						options: {
							width: "600px",
							textAlign: "center",
						},
					},
				},
				{
					target: ".eighth-step",
					content: (
						<div>
							<h4>Guests</h4>
							<br />
							<img
								src={GuestsTab}
								alt="Residents Tab"
								style={{ width: "500px" }}
							/>
						</div>
					),
					styles: {
						options: {
							width: "600px",
							textAlign: "center",
						},
					},
				},
				{
					target: ".ninth-step",
					content: (
						<div>
							<h4>Support</h4>
							<br />
							<img
								src={SupportTab}
								alt="Residents Tab"
								style={{ width: "500px" }}
							/>
						</div>
					),
					styles: {
						options: {
							width: "600px",
							textAlign: "center",
						},
					},
				},
				{
					target: ".tenth-step",
					content: (
						<div>
							<h4>Settings</h4>
							<br />
							<img
								src={SettingsTab}
								alt="Residents Tab"
								style={{ width: "500px" }}
							/>
						</div>
					),
					styles: {
						options: {
							width: "600px",
							textAlign: "center",
						},
					},
				},
				{
					target: ".eleventh-step",
					content: (
						<div className="third-step-content">
							<h4>Account Settings</h4>
							<br />
							<img src={MyAccount} alt="Account Settings" />
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".twelveth-step",
					content: (
						<div id="twelveth-step-content">
							<h4>Switching Accounts</h4>
							<br />
							<img src={SwitchAccounts} alt="Account Switch" />
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					content: (
						<div>
							<p>Thank You!</p>
							<MDBInput
								label="Do not show again"
								type="checkbox"
								id="tourNotShownAgain"
								name="tourNotShownAgain"
								onChange={this.toggleCheckboxValueTour}
							/>
							<MDBBtn color="danger" onClick={this.closeTour2}>
								Close Tour
							</MDBBtn>
						</div>
					),
					placement: "center",
					target: "body",
				},
			],
			stepsMobile: [
				{
					content: (
						<div className="first-step-content">
							<h4>Welcome to Dish Fiber!</h4>
							<p>
								Your simple Wi-Fi dashboard with real-time
								analytics
							</p>
							<MDBInput
								label="Do not show again"
								type="checkbox"
								id="tourNotShownAgain"
								name="tourNotShownAgain"
								onChange={this.toggleCheckboxValueTour}
							/>
						</div>
					),
					placement: "center",
					target: "body",
				},
				{
					target: ".fourth-step",
					content: (
						<div className="third-step-content">
							<h4>Quick Search and Links</h4>
							<p>
								Do a quick search or jump to popular features by
								clicking the icon
							</p>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".fifth-step",
					content: (
						<div className="third-step-content">
							<h4>Recent Service Activations</h4>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".sixth-step",
					content: (
						<div className="third-step-content">
							<h4>Upcoming Expirations</h4>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".seventh-step",
					content: (
						<div>
							<h4>Residents</h4>
							<br />
							<img
								src={ResidentsTab}
								alt="Residents Tab"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".eighth-step",
					content: (
						<div>
							<h4>Guests</h4>
							<br />
							<img
								src={GuestsTab}
								alt="Residents Tab"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".ninth-step",
					content: (
						<div>
							<h4>Support</h4>
							<br />
							<img
								src={SupportTab}
								alt="Residents Tab"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".tenth-step",
					content: (
						<div>
							<h4>Settings</h4>
							<br />
							<img
								src={SettingsTab}
								alt="Residents Tab"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						textAlign: "center",
					},
				},
				{
					target: ".eleventh-step",
					content: (
						<div className="third-step-content">
							<h4>Account Settings</h4>
							<br />
							<img
								src={MyAccount}
								alt="Account Settings"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					target: ".twelveth-step",
					content: (
						<div id="twelveth-step-content">
							<h4>Switching Accounts</h4>
							<br />
							<img
								src={SwitchAccounts}
								alt="Account Switch"
								style={{ width: "100%" }}
							/>
						</div>
					),
					styles: {
						minWidth: "400px",
						textAlign: "center",
					},
				},
				{
					content: (
						<div>
							<p>Thank You!</p>
							<MDBInput
								label="Do not show again"
								type="checkbox"
								id="tourNotShownAgain"
								name="tourNotShownAgain"
								onChange={this.toggleCheckboxValueTour}
							/>
							<MDBBtn color="danger" onClick={this.closeTour2}>
								Close Tour
							</MDBBtn>
						</div>
					),
					placement: "center",
					target: "body",
				},
			],
		});
	};
	setCurrentSlide = (curr) => {
		var p = document.getElementsByClassName("customClass");
		if (curr["index"] === 10) {
			return p[0].click();
		}
		if (
			p[0] !== undefined &&
			(curr["index"] !== 10 || curr["index"] !== 11)
		) {
			if (p[0].classList.contains("active") === true) {
				p[0].classList.remove("active");
			}
		}
		if (
			p[0] !== undefined &&
			(curr["index"] === 10 || curr["index"] === 11)
		) {
			if (p[0].classList.contains("active") === false) {
				p[0].classList.add("active");
			}
		}
	};
	setCurrentSlideMobile = (curr) => {
		var p = document.getElementsByClassName("customClass");
		if (p[0] !== undefined && (curr !== 8 || curr !== 9)) {
			if (p[0].classList.contains("active") === true) {
				p[0].classList.remove("active");
			}
		}
		if (p[0] !== undefined && (curr === 8 || curr === 9)) {
			if (p[0].classList.contains("active") === false) {
				p[0].classList.add("active");
				return p[0].click();
			}
		}
	};
	toggleCheckboxValueTour = () => {
		this.setState({
			tourNotShownAgain: !this.state.tourNotShownAgain,
		});
	};
	ErrorFallback = ({ error, resetErrorBoundary }) => {
		return (
			<div id="overlayError">
				<div id="overlayMessage" className="overlayMessage">
					<div role="alert">
						<h4>Something went wrong!</h4>
						<MDBBtn color="danger" onClick={resetErrorBoundary}>
							Try again
						</MDBBtn>
					</div>
				</div>
			</div>
		);
	  	};
	render() {
		const windowWidth = window.innerWidth;
		const { steps, stepsMobile } = this.state;
		const { isLoading, value, results } = this.state;
		const { t } = this.props;
		return (
			<>
				{/* {this.state.showError ?
					<MDBModal
						isOpen={true}
						size="md"
						className="form"
						centered
					>
						<MDBModalHeader className="error">
							<MDBIcon icon="exclamation-triangle" />Error
						</MDBModalHeader>
						<MDBModalBody className="error">
							<h4>An unknown error has occurred!</h4>
							<MDBBtn color="danger" onClick={() => window.location.reload(false)}>
								Try again
							</MDBBtn>
						</MDBModalBody>
					</MDBModal>
					: ""} */}
				<MDBModal
					isOpen={this.state.modalModifyServiceInfo}
					toggle={this.toggle}
					size="md"
					className="form"
					centered
				>
					<MDBModalHeader toggle={this.toggleModifyService}>
						<h4>Modified Service Plan Information</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<div>
							<MDBRow>
								<MDBCol sm="6" lg="4">
									<p>Subscriber:</p>
								</MDBCol>
								<MDBCol sm="6" lg="8">
									<p>{this.state.subscriberName}</p>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol sm="6" lg="4">
									<p>Email:</p>
								</MDBCol>
								<MDBCol sm="6" lg="8">
									<p>{this.state.subscriberEmail}</p>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol sm="6" lg="4">
									<p>New Service Plan:</p>
								</MDBCol>
								<MDBCol sm="6" lg="8">
									<p>{this.state.newServicePlanName}</p>
								</MDBCol>
							</MDBRow>
							{this.state.nextInvoiceDate !== undefined ?
								<>
									<MDBRow>
										<MDBCol sm="6" lg="4">
											<p>Next Invoice Date:</p>
										</MDBCol>
										<MDBCol sm="6" lg="8">
											<p>{this.state.nextInvoiceDate !== undefined
												? Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
												}).format(new Date(this.state.nextInvoiceDate))
												: ""}</p>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol sm="6" lg="4">
											<p>Next Invoice Amount:</p>
										</MDBCol>
										<MDBCol sm="6" lg="8">
											<p>${this.state.nextInvoiceAdjustedAmount}</p>
										</MDBCol>
									</MDBRow>
									<MDBRow className="align-items-end">
										<MDBCol sm="6" lg="4">
											<p>Billing Cycle Adjustment Amount:</p>
										</MDBCol>
										<MDBCol sm="6" lg="8">
											<p>${this.state.billingCycleAdjustment}</p>
										</MDBCol>
									</MDBRow>
								</>
								: ""}
						</div>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="danger"
							onClick={this.toggleModifyService}
							type="submit"
						>
							OK
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				{/* <Joyride
					steps={windowWidth > 767 ? steps : stepsMobile}
					run={this.state.isTourOpen}
					continuous={true}
					showProgress={true}
					styles={{
						options: {
							beaconSize: 1,
						},
					}}
					spotlightClicks={true}
					scrollDuration={500}
				/> */}
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={10000}
				/>
				<ErrorBoundary
					FallbackComponent={this.ErrorFallback}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<div id="venueManagement">
						{this.state.redirect ? (
							<MDBModal
								isOpen={this.state.modalModifyResident}
								toggle={this.toggle}
								size="fluid"
								className="form"
							>
								<ModifyResident
									toggleModifyResident={
										this.toggleModifyResident
									}
									firstName={this.state.SubscriberGivenName}
									lastName={this.state.SubscriberFamilyName}
									email={this.state.SubscriberEmail}
									phoneNumber={
										this.state.SubscriberPhoneNumber
									}
									receivetext={this.state.contactpref_email}
									receivephone={this.state.contactpref_sms}
									contactpref_sms={this.state.contactpref_sms}
									contactpref_email={this.state.contactpref_email}
									language={this.state.SubscriberLocale}
									floor={this.state.UnitFloor}
									building={this.state.UnitBuilding}
									startDate={this.state.StartDateTime}
									endDate={this.state.EndDateTime}
									unitID={this.state.UnitID}
									unitID_internal={this.state.unitID_internal}
									submitModify={this.submitModify}
									venueID={this.state.selectedID}
									updateName={this.updateName}
									loading={this.state.loading}
									SSID={this.state.ssid}
									servicePlanID={this.state.servicePlanID}
									servicePlan={this.state.servicePlan}
									submitDelete={this.submitDelete}
									availablePlans={
										this.state.venueServicePlans
									}
									vlan={this.state.vlan}
									status={this.state.status}
									loadingDelete={this.state.loadingDelete}
									realpageSyncDate={
										this.state.realpageSyncDate
									}
									subscriberManagedviaSSO={
										this.state.subscriberManagedviaSSO
									}
									StripeClientSecret={
										this.state.StripeClientSecret
									}
									paymentFailed={this.state.paymentFailed}
									RealPageUnitID={this.state.RealPageUnitID}
									country={this.state.country}
									subscriptionDiscount={this.state.subscriptionDiscount}
									venueDiscount={this.state.venueDiscount}
									externalSubscriberID={this.state.externalSubscriberID}
									selectedServicePlanPrice={this.state.selectedServicePlanPrice}
									setupIntentID={this.state.setupIntentID}
         showUpdate={this.state.showUpdate}
         cancelInputType={this.state.cancelInputType}
                  								/>
							</MDBModal>
						) : null}
						<Navigation
							updateName={this.updateName}
							updateVenueList={this.updateVenueList}
							selectedName={this.state.selectedName}
							selectedID={this.state.selectedID}
							address1={this.state.address1}
							address2={this.state.address2}
							city={this.state.city}
							state={this.state.state}
							postalCode={this.state.postalCode}
							activeData={this.state.activeData}
							allActiveData={this.state.allActiveData}
							keypoolID={this.state.keypoolID}
							expirations={this.state.expiringData14Days}
							guestData={this.state.guestData}
							guestDataExpiring={this.state.guestDataExpiring}
							venueData={this.state.venueData}
							venueAdmins={this.state.venueAdmins}
							leasingAgents={this.state.leasingAgents}
							tickets={this.state.tickets}
							loading={this.state.loading}
							venueType={this.state.venueType}
							buildings={this.state.buildings}
							updateSupport={this.getSupportDetails}
							coreEquipmentState={this.state.coreEquipmentState}
							cpeEquipmentState={this.state.cpeEquipmentState}
							venueList={this.state.venueList}
							availablePlans={this.state.venueServicePlans}
							modalResidentManagement={
								this.state.modalResidentManagement
							}
							closeTour={this.closeTour}
							handleSwitchChange={this.handleSwitchChange}
							darkMode={this.state.darkMode}
       showUpdate={this.state.showUpdate}
       cancelInputType={this.state.cancelInputType}
       s3Directory={this.state.s3Directory}
       logo={this.state.logo}
													/>
						<div id="overlayResidentManagement">
							<div id="overlayMessage" className="overlayMessage">
								{this.state.postMessage}
							</div>
						</div>
						<div className="mainDashboard">
							<MDBContainer fluid>
								<MDBRow className="dashboardRow">
									<MDBCol
										sm="12"
										md="12"
										lg="5"
										xl="4"
										className="summaryCards"
									>
										<MDBCard>
											<MDBCardBody>
												<ActionSummary
													newResidentsToday={
														this.state
															.activeData1Day
													}
													newResidents7Days={
														this.state
															.activeData7Day
													}
													newResidentsTodayCount={
														this.state.venueData[
														"UpcomingSubscriptionStartCount_1day"
														]
													}
													newResidents7DaysCount={
														this.state.venueData[
														"UpcomingSubscriptionStartCount_7day"
														]
													}
													expiring={
														this.state.venueData[
														"UpcomingSubscriptionEndCount_14day"
														]
													}
													venueType={
														this.state.venueType
													}
													recentResidents={
														this.state.activeData
													}
													allRecentResidents={
														this.state.allActiveData
													}
													venueID={
														this.state.selectedID
													}
													venueName={
														this.state.selectedName
													}
													updateName={this.updateName}
													expirations={
														this.state
															.expiringData14Days
													}
												/>
											</MDBCardBody>
										</MDBCard>
										<MDBCard>
											<MDBCardBody>
												<ResidentSummary
													assignedUnits={
														this.state.venueData[
														"UnitsWithSubscriptionsCount"
														]
													}
													unassignedUnits={
														this.state.venueData[
														"UnitsWithoutSubscriptionsCount"
														]
													}
													pendingAUP={
														this.state.venueData[
														"PendingAUP"
														]
													}
													UnitsBuildingFloorListSusbcribers={
														this.state
															.UnitsBuildingFloorListSusbcribers
													}
													availablePlans={this.state.venueServicePlans}
													venueID={this.state.selectedID}
													updateName={this.updateName}
												/>
											</MDBCardBody>
										</MDBCard>
										<MDBCard>
											<MDBCardBody>
												<Support
													ticketCount={
														this.state.ticketCount
													}
													coreEquipmentState={
														this.state
															.coreEquipmentState
													}
													cpeEquipmentState={
														this.state
															.cpeEquipmentState
													}
													selectedName={
														this.state.selectedName
													}
													venueList={
														this.state.venueList
													}
													venueListName={
														this.state.venueListName
													}
													venueType={
														this.state.venueType
													}
													tickets={this.state.tickets}
													selectedID={
														this.state.selectedID
													}
													updateSupport={
														this.getSupportDetails
													}
													updateName={this.updateName}
													unitID={this.state.UnitID}
													floor={this.state.UnitFloor}
													building={
														this.state.UnitBuilding
													}
                          												/>
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
									<MDBCol>
										<div className="fourth-step">
											<Grid>
												<p>{t("quicksearch.title")}</p>
												<Grid.Column width={16}>
													<Search
														category
														loading={isLoading}
														onResultSelect={
															this
																.handleResultSelect
														}
														onSearchChange={_.debounce(
															this
																.handleSearchChange,
															500,
															{
																leading: true,
															}
														)}
														results={results}
														placeholder={
															this.state
																.venueType ===
																"HOTSPOT"
																? "Subscriber Name, Unit ID or Email"
																: t(
																	"quicksearch.label"
																)
														}
														value={value}
														className="quickSearch"
													/>
												</Grid.Column>
											</Grid>
											<QuickLinks
												keypoolID={this.state.keypoolID}
												venueID={this.state.selectedID}
												venueName={
													this.state.selectedName
												}
												refresh={this.refresh}
												recentResidents={
													this.state.activeData
												}
												updateName={this.updateName}
												venueAdmins={
													this.state.venueAdmins
												}
												leasingAgents={
													this.state.leasingAgents
												}
												venueType={this.state.venueType}
												buildings={this.state.buildings}
												availablePlans={
													this.state.venueServicePlans
												}
											/>
										</div>
										<MDBCard className="fifth-step">
											<MDBCardBody>
												{this.state.activeData !==
													undefined ? (
													<>
														<RecentResidents
															recentResidents={
																this.state
																	.activeData
															}
															allRecentResidents={
																this.state
																	.allActiveData
															}
															venueID={
																this.state
																	.selectedID
															}
															venueName={
																this.state
																	.selectedName
															}
															updateName={
																this.updateName
															}
															availablePlans={
																this.state
																	.venueServicePlans
															}
															getVenueDetails={
																this
																	.getVenueDetails
															}
               showUpdate={this.props.showUpdate}
               cancelInputType={this.props.cancelInputType}
														/>
													</>
												) : (
													<RecentResidents
														venueID={
															this.state
																.selectedID
														}
              showUpdate={this.props.showUpdate}
              cancelInputType={this.props.cancelInputType}
													/>
												)}
											</MDBCardBody>
										</MDBCard>
										<MDBCard className="sixth-step">
											<MDBCardBody>
												<UpcomingExpirations
													expirations={
														this.state.expiringData14Days
													}
													updateName={this.updateName}
													venueID={
														this.state.selectedID
													}
													venueName={
														this.state.selectedName
													}
             showUpdate={this.props.showUpdate}
             cancelInputType={this.props.cancelInputType}
												/>
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
						<div className="footer">
							<MDBContainer fluid>
								<MDBRow>
									<MDBCol className="securedByVaultImage">
										<LogoFooter
											darkMode={localStorage.getItem(
												"darkMode"
											)}
										/>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
					</div>
				</ErrorBoundary>
			</>
		);
	}
}
export default withTranslation()(Dashboard);